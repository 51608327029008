/* eslint-disable new-cap */
import {Component} from '@angular/core';
import {AnalyticsService} from './services/analytics.service';
import {environment} from '../environments/environment';
import {Router, ActivationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
/**
 * App class
 */
export class AppComponent {
    currentPath: any;
    showCookieModal: any;
    showCookiePage: any;
    /**
     *
     * @param {Service} analyticsService
     * @param {Router} router
     */
    constructor(private analyticsService: AnalyticsService,
       public router: Router) {
        this.appendAnalytics();
        this.appendCookies();

        router.events
            .pipe(filter((event: any) => event instanceof ActivationEnd))
            .subscribe((e: ActivationEnd) => {
                this.currentPath = e.snapshot.url.length ?
                    e.snapshot.url[0].path : null;
                this.analyticsService.sendPageView();
            });
    }
    /**
     * append to index.html as js script
     */
    appendAnalytics() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaMeasurementId}`;
        document.body.append(s);

        const t = document.createElement('script');
        t.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${environment.gaMeasurementId}');`;
        document.body.append(t);
    }

    /**
     * append to index.html as js script
     */
    appendCookies() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
        s.setAttribute('data-domain-script', environment.cookiesId);
        document.body.append(s);

        const t = document.createElement('script');
        t.innerHTML = 'function OptanonWrapper() { }';
        document.body.append(t);
    }
}
