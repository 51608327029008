/* eslint-disable new-cap */
import {Component, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContentService} from 'src/app/services/content.service';
import {AnalyticsService} from 'src/app/services/analytics.service';
import {environment} from 'src/environments/environment';
import StoryblokClient, {ISbConfig} from 'storyblok-js-client';


// declare global {
//     interface Window {
//       storyblok: any;
//     }
//   }
const Storyblok = new StoryblokClient({
    accessToken: environment.contentKey,
    cache: {
        type: 'memory',
    },
});

@Component({
    selector: 'app-nameplate',
    templateUrl: './nameplate.component.html',
    styleUrls: ['./nameplate.component.scss'],
})
/**
 * Nameplate class
 */
export class NameplateComponent {
    // @Input() nameplate: any;
    nameplate:any;
    @Input() brand: any;
    @Input() _editable: any;


    /**
     * @param {ActivatedRoute} route
     * @param {Function} contentSevice
     * @param {Function} analyticsService
     */
    constructor(
        private route: ActivatedRoute,
        private contentService: ContentService,
        private analyticsService: AnalyticsService,
    ) {
        this.route.params.subscribe(async (params) => {
            this.nameplate = await
            this.contentService.getStoryById(params['id']);
            this.brand = await
            this.contentService.getStoryByUuid(this.nameplate.brand);
            console.log('brand name', this.brand);
            console.log('this.nameplate', this.nameplate);
            // eslint-disable-next-line max-len
            const testStory = await this.contentService.getStoryByIdTest(params['id']);
            console.log(testStory.id);
            debugger;
            const {StoryblokBridge, location} = window;
            const storyblokInstance = new StoryblokBridge({
            });
            storyblokInstance.on(['published', 'change'], (event) => {
                debugger;
                console.log(event);
                // reload page if save or publish is clicked
                location.reload();
                // console.log('event');
            });


            storyblokInstance.on('input', (event: any) => {
                debugger;
                console.log(event);
                if (this.nameplate && event.story.content._uid ===
                        this.nameplate._uid) {
                    this.nameplate= event.story.content;
                    console.log('Test Story Updated:', this.nameplate);
                }
            });
        });
    }


    /**
     * pass napmeplate values as google analytics event
     * @param {string} url
     * @param {string} type
     */
    goToUrl(url: string | URL | undefined, type:string) {
        const eventLabel = `${this.nameplate.year} ${this.nameplate.name}`;
        const eventType = `${eventLabel}:${type}`;
        this.analyticsService.sendEvent(eventType, this.brand.name, eventLabel);
        window.open(url, '_blank');
    }
}

