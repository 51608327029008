import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {apiPlugin, ISbStoryData, SbBlokData, storyblokInit, useStoryblokBridge} from '@storyblok/js';


const {storyblokApi} = storyblokInit({
    accessToken: environment.contentKey,
    bridge: true,
    use: [apiPlugin],
});
/**
 * Injectable
 */
// eslint-disable-next-line new-cap
@Injectable({
    providedIn: 'root',
})
/**
 * Content from storyblok
 */
export class ContentService {
    // story = 'shows';
    page = 'sema';
    spaceId = environment?.spaceId;
    locale = 'en';
    storyId:any;
    story:any;
    /**
     *
     */
    constructor() {
        // Initialize an initial state if needed
        // useStoryblokBridge(this.storyId, (story) =>
        //     (this.state.story = story),
        // );
    }


    /**
     * Gey current space version
     * @return {object} version
     */
    async getCurrentVersion() {
        const url = `https://api.storyblok.com/v2/cdn/spaces/${this.spaceId}?token=${environment.contentKey}`;
        const resp = await fetch(url);
        const data = await resp.json();
        return data.space.version;
    }

    /**
     *  get a single story
     * @param {string} id
     * @return {object} content
     */
    async getStoryById(id: string) {
        const cv = await this.getCurrentVersion();
        // const params = {
        //     cv: cv,
        // };
        console.log(cv);
        const response = await
        storyblokApi?.get(`cdn/stories/nameplates/${id}`, {
            version: 'draft',
        });
        console.log(response?.data.story);
        return response?.data.story.content;
    }

    /**
     *  get a single story
     * @param {string} id
     * @return {object} content
     */
    async getStoryByIdTest(id: string) {
        const cv = await this.getCurrentVersion();
        // const params = {
        //     cv: cv,
        // };
        console.log(cv);
        const response = await
        storyblokApi?.get(`cdn/stories/nameplates/${id}`, {
            version: 'draft',
        });
        console.log(response?.data.story);
        return response?.data.story;
    }


    /**
     * @param {string} uuid
     * @return {object} content
     */
    async getStoryByUuid(uuid: string) {
        console.log(uuid);
        const cv = await this.getCurrentVersion();
        const url = `https://api.storyblok.com/v2/cdn/stories/${uuid}?token=${environment.contentKey}&version=${cv}&find_by=uuid`;
        const resp = await fetch(url);
        const data = await resp.json();
        return data.story.content;
    }
}
