/* eslint-disable new-cap */
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NameplateComponent} from './components/nameplate/nameplate.component';

const routes: Routes = [
    {
        path: 'nameplates/:id',
        component: NameplateComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule],
})
/**
 * Routing class
 */
export class AppRoutingModule {

}
