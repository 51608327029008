<button class="button" 
  [class.white]="theme === 'white'" 
  [class.dark]="theme === 'dark'"

  [class.ford]="brand === 'ford'"
  [class.lincoln]="brand === 'lincoln'"
  [class.primary]="primary"

  (click)="onClick()">

  <div class="button-text"
  [class.white]="theme === 'white'" 
  [class.dark]="theme === 'dark'"
    >
    <ng-content></ng-content>
  </div>
</button>