/* eslint-disable camelcase */
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

/**
 * Injectable
 */
// eslint-disable-next-line new-cap
@Injectable({
    providedIn: 'root',
})

/**
 * Analytics class
 */
export class AnalyticsService {
    /**
    * Send data to google analytics
    */
    sendPageView() {
        gtag('config', environment.gaMeasurementId);
    }

    /**
     *  send button label and category as event
     * @param  {string} val
     * @param  {string} event_category
     * @param {string} event_label
     */
    sendEvent(val: string, event_category: string, event_label: string) {
        gtag('event', val, {
            'event_category': event_category,
            'event_label': event_label,
        });
    }
}
