<div class="container"  *ngIf="nameplate && brand">

  <div class="background_image" >
    
      <div  *ngIf="nameplate.header_theme === 'white'" id="header_white_gradient"></div>
      <div  *ngIf="nameplate.header_theme === 'grey'" id="header_grey_gradient"></div>
      <div  *ngIf="nameplate.header_theme === 'dark'" id="header_dark_gradient"></div>
    
      <img [appStoryblok]="_editable" src="{{ nameplate.background_mobile.filename }}" class="mobile-image" />
    <img [appStoryblok]="_editable" src="{{ nameplate.background_desktop.filename }}" class="desktop-image" />
    <img [appStoryblok]="_editable" src="{{ nameplate.background_tablet.filename }}" class="tablet-image" />

    <div [appStoryblok]="_editable" *ngIf="nameplate.footer_theme === 'white'" id="footer_white_gradient"></div>
    <div [appStoryblok]="_editable" *ngIf="nameplate.footer_theme === 'grey'" id="footer_grey_gradient"></div>
    <div [appStoryblok]="_editable" *ngIf="nameplate.footer_theme === 'dark'" id="footer_dark_gradient"></div>
  </div>

  <div class="nameplate__container">
   
    <div class="logo__container" [appStoryblok]="_editable">
      
      <img [appStoryblok]="_editable" src="{{ nameplate.logo_mobile.filename }}" class="mobile-logo" />
      <img [appStoryblok]="_editable" src="{{ nameplate.logo_desktop.filename }}" class="desktop-logo" />
    </div>
    <div class="nameplate__content" [appStoryblok]="_editable">

     
      <div class="test_drive" [appStoryblok]="_editable">
        <app-button *ngIf="nameplate.test_drive.url !=''"   
        [theme]="nameplate.header_theme === 'white' ? 'white' : 'dark'"
          [brand]="brand?.name?.toLowerCase() === 'ford' ? 'ford' : 'lincoln'" [primary]="true"
          (btnClick)="goToUrl(nameplate.test_drive.url, 'test_drive')">
          $50 Test Drive Offer
        </app-button>
      </div>

      <div class="build" [appStoryblok]="_editable">
        <app-button *ngIf="nameplate.build_and_price.url !=''" 
        [theme]="nameplate.header_theme === 'white' ? 'white' : 'dark'"
          [brand]="brand?.name?.toLowerCase() === 'ford' ? 'ford' : 'lincoln'"
          (btnClick)="goToUrl(nameplate.build_and_price.url, 'build_and_price')">
          Build & Price
        </app-button>
      </div>
      <div class="discover" [appStoryblok]="_editable">
        <app-button *ngIf="nameplate.discover_more.url !=''"
        [theme]="nameplate.header_theme === 'white' ? 'white' : 'dark'"

          [brand]="brand?.name?.toLowerCase() === 'ford' ? 'ford' : 'lincoln'"
          (btnClick)="goToUrl(nameplate.discover_more.url, 'discover_more')">
          Discover More
        </app-button>
      </div>
      <div class="ford_merchandise" [appStoryblok]="_editable">
      <app-button *ngIf="nameplate.ford_merchandise.url !=''" 
      [theme]="nameplate.header_theme === 'white' ? 'white' : 'dark'"
      [brand]="brand?.name?.toLowerCase() === 'ford' ? 'ford' : 'lincoln'"
      (btnClick)="goToUrl(nameplate.ford_merchandise.url, 'merchandise')">
        Ford Merchandise
      </app-button>
    </div>
    <div class="window_sticker" [appStoryblok]="_editable">
      <app-button *ngIf="nameplate.pdf_button.filename !== ''"
      [theme]="nameplate.header_theme === 'white' ? 'white' : 'dark'"
        [brand]="brand?.name?.toLowerCase() === 'ford' ? 'ford' : 'lincoln'"
        (btnClick)="goToUrl(nameplate.pdf_button.filename, 'download-pdf')">
        Window Sticker
      </app-button>
    </div>
    <div *ngIf="nameplate.Bronco_off_rodeo.url" class="Bronco_off_rodeo" [appStoryblok]="_editable">
      <app-button
      *ngIf="nameplate.Bronco_off_rodeo.url !=''" 
      [theme]="nameplate.header_theme === 'white' ? 'white' : 'dark'"
      [brand]="brand?.name?.toLowerCase() === 'ford' ? 'ford' : 'lincoln'"
      (btnClick)="goToUrl(nameplate.Bronco_off_rodeo.url, 'Bronco-Off-Roadeo')">
      Bronco Off-Roadeo
    </app-button>
  </div>


    </div>
    <div class="nameplate_name_year_container" [appStoryblok]="_editable">
      <!-- <div *ngIf="nameplate.header_theme === 'white' ? 'white' : 'dark'"
       class="nameplate_name_year {{brand?.name.toLowerCase()}}">
        {{nameplate.year}} {{nameplate.name}}
      </div> -->
      <div class="nameplate_name_year" 
      [ngClass]="{'white': nameplate.footer_theme !== 'white', 
                  'black': nameplate.footer_theme === 'white', 
                  'ford': true}">
     {{nameplate.year}} {{nameplate.name}}
 </div>
 

      <!-- <div *ngIf="nameplate.compliance" class="nameplate__disclaimer">
        {{nameplate.compliance}}
      </div> -->
      <div *ngIf="nameplate.compliance" [appStoryblok]="_editable"
     class="nameplate__disclaimer"
     [ngClass]="{'black': nameplate.footer_theme === 'white',
                  'white': nameplate.footer_theme !== 'white',
                  'nameplate__disclaimer': true
    }">
  {{nameplate.compliance}}
</div>

    </div>
    
  </div>
</div>