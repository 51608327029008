/* eslint-disable new-cap */
import {Directive, ElementRef, Input} from '@angular/core';
import {SbBlokData, storyblokEditable} from '@storyblok/js';

// eslint-disable-next-line new-cap
@Directive({
    selector: '[appStoryblok]',
})
/**
  * Storyblok directive class
 */
export class StoryblokDirective {
  @Input('appStoryblok') appStoryblok: SbBlokData | undefined;
  /**
   *
   // eslint-disable-next-line valid-jsdoc
   */
  constructor(private el: ElementRef) {}
  /**
   * ngOnInit
   *
   */
  ngOnInit() {
      if (!this.appStoryblok) {
          return;
      }


      const options = storyblokEditable(this.appStoryblok);
      this.el.nativeElement.setAttribute('data-blok-c', options['data-blok-c']);
      this.el.nativeElement.setAttribute('data-blok-uid',
          options['data-blok-uid']);
      this.el.nativeElement.classList.add('storyblok__outline');
  }
}
