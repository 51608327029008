/* eslint-disable new-cap */
import {Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * component
 */
// eslint-disable-next-line new-cap
@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
/**
 * Button component
 */
export class ButtonComponent {
    @Input() _editable: string | undefined;
    public buttonText ='';

    /**
     * setter
     * @param {string} name
     */
  @Input()
    set text(name: string) {
        this.buttonText = name.toUpperCase();
    }
    /**
     * getters
     */
  get name(): string {
      return this.buttonText;
  }
  @Input()theme: 'white' | 'dark' = 'white';
  @Input()width!: string;
  @Input()brand: 'ford' | 'lincoln' = 'ford';
  @Input() primary = false;
  @Output() btnClick = new EventEmitter();

  /**
   * Onclick handler
   */
  onClick() {
      this.btnClick.emit();
  }
}
