/* eslint-disable new-cap */
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NameplateComponent} from './components/nameplate/nameplate.component';
import {ButtonComponent} from './components/button/button.component';
import {StoryblokDirective} from './directives/storyblok.directive';

@NgModule({
    declarations: [
        AppComponent,
        NameplateComponent,
        ButtonComponent,
        StoryblokDirective,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
/**
 * App module
 */
export class AppModule { }
